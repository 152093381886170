body {
  background-color: #0e2439;
  color: #9cb3c9;
  transition: filter 0.2s linear;
  margin: 30px;
}
.App {
  font-family: sans-serif;
}
.italic {
  font-style: italic;
  margin: 10px 0;
}
.usage {
  margin: 30px 0;
}
.usage-last {
  margin-bottom: 30px;
}
.def {
  margin: 10px 0;
}
button {
  margin: 10px;
  border: 2px solid #9cb3c9;
  background: transparent;
  color: #9cb3c9;
  padding: 10px 15px;
  outline: none;
  cursor: pointer;
  font-size: 0.85rem;
  border-radius: 2px;
  transition: all 0.1s linear;
}
button:hover {
  background: #9cb3c9;
  color: #0e2439;
}

.radioContainer {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#radio {
  width: 200px;
  height: 200px;
  transform: translateX(-10px) translateY(0) rotate(-10deg);
}
iframe {
  width: 500px;
}
footer {
  margin-top: 150px;
}
.footerSection {
  font-style: italic;
  font-size: 14px;
}
@media only screen and (max-width: 600px) {
  #radio {
    display: none;
  }
  .radioContainer {
    margin-left: 10px;
    margin-right: 10px;
  }
  iframe {
    width: 100%;
  }
}
